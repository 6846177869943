#article-index {
  h1 {
    display: inline-block;
  }
  .search-button {
    cursor: pointer;
  }
}

.article-item {
  margin-bottom: 15px;
  .article-title,
  .article-title a
  {
    color: inherit;
    margin: 0;
  }
  .article-meta,
  .article-meta a
  {
    color: #999;
    font-size: .85em;
  }
  .article-meta a{
    text-decoration: underline;
  }
  .article-thumb {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .article-text {
    margin-top: 7px;
  }
}

@media print {
  header, .navigation, #footer, .print, .build-div, .breadcrumb, form, #fullContent, .article-img {
    display: none !important;
  }
  .items {
    padding: 0 !important;
  }
  .card {
    border: 0;
  }
  #articleContent > p:nth-child(n+5) {
    display: inherit !important;
  }
  .no-print {
    display: none!important;
  }
}

@tablet: ~"(max-width: 768px)";

label.required::before {
  content: '* ';
  color: #d00;
}

.input-label {
  display: inline-block;
  font-size: 1rem;
  position: absolute;
  transition: all ease-in .1s;
  color: #7c7c7c !important;
  top: 0;
  overflow: hidden;
  /*white-space: nowrap;*/
  width: calc(100% - 0.5rem);
  background: #fff;
  margin: 1px 0 0 1px;
  padding: .55rem 0 .55rem .75rem;
  border-radius: @base-radius;
  line-height: 17px;
  text-align: left;
  justify-content: flex-start !important;
  box-sizing: border-box;
  & label {
    transition: all ease-in .1s;
  }
  @media @tablet {
    position: initial;
  }
 /* &::after {
    display: block;
    content: ' ';
    position: absolute;
    width: 40px;
    height: 2.5rem;
    top: 0;
    right: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 80%);
  }*/
  &.active {
    font-size: 12px;
    justify-content: flex-start;
    background: transparent;
    margin: 0;
    padding: 0 0 0 .75rem;
    line-height: 14px;
    @media @tablet {
      padding: .55rem 0 .55rem .75rem;
      font-size: 1rem;
    }
    & label {
      font-size: 12px;
      @media @tablet {
        font-size: 1rem;
      }
    }
    &::after {
      height: 12px;
      top: 6px;
    }
  }
}

.input-label-sm {
  padding: .35rem 0 .35rem .75rem;
}




.input-parent {
  position: relative;
  @media @tablet {
    display: flex;
    flex-direction: column-reverse;
  }
  input, select, textarea {
    transition: all ease-in .05s;
  }
  input::placeholder, select::placeholder, textarea::placeholder {
    display: none;
  }
  select {
    padding-left: .35rem;
  }
  input.is-val, input:focus, textarea.is-val, textarea:focus {
    padding: .7rem .75rem .05rem;
    @media @tablet {
      padding: 0.375rem 0.75rem
    }
  }
  select.is-val, select:focus {
    padding: .7rem .75rem .05rem .35rem;
    @media @tablet {
      padding: 0.375rem 0.75rem
    }
  }
}

input[readonly] + .input-label {
  &::after {
    /*background-image: linear-gradient(90deg, rgba(233, 236, 239, 0) 0%, rgb(233, 236, 239) 80%);*/
  }
}
@import "./_article.less";
@fa-font-path: "../../../vendor/fortawesome/font-awesome/webfonts";
@import "../../../vendor/fortawesome/font-awesome/less/fontawesome.less";
@import "../../../vendor/fortawesome/font-awesome/less/solid.less";

@import "~@fancyapps/fancybox/dist/jquery.fancybox.min.css";

@base-radius: 4px;

html,
body {
    height: 100%;
}

.wrap {
    min-height: 100%;
    height: auto;
    margin: 0 auto -60px;
    padding: 0 0 60px;
}

.wrap > .container {
    padding: 70px 15px 20px;
}

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    padding-top: 20px;
}

.jumbotron {
    text-align: center;
    background-color: transparent;
}

.jumbotron .btn {
    font-size: 21px;
    padding: 14px 24px;
}

.not-set {
    color: #c55;
    font-style: italic;
}

/* add sorting icons to gridview sort links */
a.asc:after,
a.desc:after {
    .fa-icon;
    .fas;
    margin-left: 5px;
}
a.asc:after, .sort-ordinal a.asc:after {
    content: @fa-var-sort-amount-down-alt;
}
a.desc:after, .sort-ordinal a.desc:after {
    content: @fa-var-sort-amount-down;
}
.sort-numerical a.asc:after {
    content: @fa-var-sort-numeric-down;
}
.sort-numerical a.desc:after {
    content: @fa-var-sort-numeric-down-alt;
}
.grid-view th {
    white-space: nowrap;
}

.grid-view th {
    white-space: nowrap;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

.autocomplete-suggestions {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgb(0, 0, 0, .3);
    overflow: auto;

    div {
        cursor: pointer;
        padding: 3px 10px;
    }

    div:hover, .autocomplete-selected {
        background: #eeeeee;
    }
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%7D191D' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%7D191D' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.img-link img {
    max-height: 400px;
}

.carousel .img-link {
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200px;
}

label.required::after {
    content: '*';
    color: #d00;
}

div.required label::after {
    content: '*';
    color: #d00;
}

@media screen and (max-width: 750px) {
    .col-photo {
        text-align: center;
    }
}

@import "./_input.less";
@import "./_print.less";
